import errorHandler from './handler/erroHandler';
import axiosInstance from './manager/manager.service';

export const actualiteService = {
    create,
    update,
    get,
    updateStatus,
    del
};

async function create(actu) {

    try {
        let ret = await axiosInstance.post(`/actualites`, actu);

        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function get() {

    try {
        let ret = await axiosInstance.get(`/actualites`);

        return ret.data;
    } catch (error) {
        console.log(error);
        return [];
    }

}

async function update(data, id) {
    try {
        let ret = await axiosInstance.put(`/actualites/${id}`, data);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}

async function updateStatus(data, id) {
    try {
        let ret = await axiosInstance.put(`/actualites/status/${id}`, data);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}


async function del(id) {
    try {
        let ret = await axiosInstance.delete(`/actualites/${id}`);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}


