<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import creatingButton from "@/components/creatingBtn";
import { toastUtils } from "../../../utils/toast.utils";
import { actualiteService } from "../../../services/actualite.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";

export default {
    page: {
        title: "Actualités",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, creatingButton, ckeditor: CKEditor.component },
    data() {
        return {
            title: "Gestion des actualités",
            isAdd: false,
            isUpdate: false,
            isNotValid: false,
            filesBaseUrl: process.env.VUE_APP_FILE_URL,
            titleModel: !this.isUpdate ? 'Enregistrement' : 'Mise à jours',
            items: [
                {
                    text: "Programmes",
                    href: "/"
                },
                {
                    text: "Actualités",
                    active: true
                }
            ],
            actualiteData: [],
            currentPage: 1,
            totalRows: 1,
            perPage: 5,
            pageOptions: [5, 10, 25, 50],
            filter: null,
            filterOn: [],
            sortBy: "id",
            sortDesc: false,
            fields: [
                { key: "image", sortable: true, label: "Image" },
                { key: "titre", sortable: true, label: "Titre"},
                // { key: "contenu", sortable: true, label: "Contenu" },
                { key: "status", sortable: true, label: "Status" },
                { key: "action" }
            ],
            actualiteForm: {
                titre: "",
                contenu: ""
            },
            submitted: false,
            imageActualite: null,
            editor: ClassicEditor,


        };
    },
    validations: {
        actualiteForm: {
            titre: { required },
            contenu: { required }
        },
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.actualiteData.length;
        },

    },
    mounted() {
        this.getActualites();
        this.totalRows = this.actualiteData.length;

    },
    methods: {

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        showAddForm(show) {
            this.isAdd = show;
            if (!show) {
                this.clearForm();
                this.submitted = false;
                this.isUpdate = false;
            }
        },

        formSubmit() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                this.$bvModal.show('modal-center');
            }
        },

        async formValidate() {
            if (this.imageActualite == null && !this.isUpdate) {
                toastUtils.error(this.$bvToast, 'Enregistrement', 'Entrer une image!');
                return;
            }
            let actuF = new FormData();
            actuF.append("titre", this.actualiteForm.titre);
            actuF.append("contenu", this.actualiteForm.contenu);
            if (this.imageActualite != null) actuF.append("image", this.imageActualite.file);

            let resp = this.isUpdate ? await actualiteService.update(actuF, this.actualiteForm.id) : await actualiteService.create(actuF);

            if (resp >= 400) {
                toastUtils.error(this.$bvToast, 'Enregistrement', 'Une erreur s\'est produite. Veuillez réssayer');
            } else {
                this.clearForm();
                this.isAdd = false;
                toastUtils.success(this.$bvToast, 'Enregistrement', 'Enregistrement reussi');
                await this.getActualites();
            }
        },

        async getActualites() {
            this.actualiteData = await actualiteService.get();
        },

        clearForm() {
            this.$v.$reset();
            this.actualiteForm.titre = '';
            this.actualiteForm.contenu = '';
            this.imageActualite = null;
        },

        showUpdateForm(row) {
            this.actualiteForm.titre = row.titre;
            this.actualiteForm.contenu = row.contenu;
            this.actualiteForm.id = row.id;
            this.isAdd = true;
            this.isUpdate = true;
        },
        showDeleteModal(item) {
            this.deleteId = item.id;
            this.$bvModal.show('delete-modal-center');

        },
        async deleteData() {

            let resp = await actualiteService.del(this.deleteId);

            if (resp >= 400) {
                toastUtils.error(this.$bvToast, 'Suppression', 'Une erreur s\'est produite. Veuillez réssayer');
            } else {
                await this.getActualites();
            }
            this.deleteId = null;
        },
        fileDeleted: function (fileRecord) {
            var i = this.imageActualite.indexOf(fileRecord);
            if (i !== -1) {
                this.imageActualite.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        },
        async changeStatus(item) {
            await actualiteService.updateStatus({ status: !item.status}, item.id);
            this.getActualites();
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <creatingButton v-if="!isAdd" :title="'Ajouter'" @click.native="showAddForm(true)" />
        <br>
        <div v-if="isAdd" class="card">
            <div class="card-header">Ajouter une actualité</div>
            <div class="col-md-12  card-body">
                <form class="row needs-validation" @submit.prevent="formSubmit">

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="validationCustom01">Titre</label>
                            <input id="validationCustom01" v-model="actualiteForm.titre" type="text" class="form-control"
                                placeholder="Titre" :class="{ 'is-invalid': submitted && $v.actualiteForm.titre.$error }" />
                            <div v-if="submitted && $v.actualiteForm.titre.$error" class="invalid-feedback">
                                <span v-if="!$v.actualiteForm.titre.required">Ce champs est requis.</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Image</label>
                            <VueFileAgent @delete="fileDeleted($event)" v-model="imageActualite" :multiple="false"
                                :deletable="true" :accept="'image/png, image/jpeg, image/jpg'" :maxSize="'3MB'"
                                :maxFiles="1" :helpText="'Sélectionner l\'image'" :errorText="{
                                    type: 'Type de fichier invalid',
                                    size: 'Votre fichier ne peux excéder 3Mo',
                                }">
                            </VueFileAgent>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="validationCustom02">Contenu</label>
                                <ckeditor v-model="actualiteForm.contenu" :editor="editor"></ckeditor>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <b-button variant="primary" @click="formSubmit">
                            <template>
                                <span v-if="isAdd && !isUpdate">Enregistrer</span>
                                <span v-if="isAdd && isUpdate">Modifier</span>
                            </template>
                        </b-button>
                        <button class="btn btn-secondary ml-2" @click="showAddForm(false)">Annuler</button>
                    </div>
                    <b-modal id="modal-center" centered :title=titleModel title-class="font-18" @ok="formValidate">
                        <p>
                            Confirmez-vous l'action?
                        </p>
                    </b-modal>
                </form>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                LISTE DES ACTUALITES
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                    Voir&nbsp;
                                    <b-form-select v-model="perPage" size="sm"
                                        :options="pageOptions"></b-form-select>&nbsp;resultats
                                </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                <label class="d-inline-flex align-items-center">
                                    Recherche:
                                    <b-form-input v-model="filter" type="search"
                                        class="form-control form-control-sm ml-2"></b-form-input>
                                </label>
                            </div>
                        </div>
                        <!-- End search -->
                    </div>
                    <div class="table-responsive">
                        <b-table :items="actualiteData" :fields="fields" responsive="sm" :per-page="perPage"
                            :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                            :filter-included-fields="filterOn" @filtered="onFiltered" show-empty
                            :empty-text="'Aucun resultat'" :empty-filtered-text="'Aucun resultat'">
                            <template v-slot:cell(action)="row">

                                <a href="javascript:void(0);" @click="changeStatus(row.item)" class="mr-3 text-primary"
                                    v-b-tooltip.hover data-toggle="tooltip" :title="row.item.status ? 'Désactivé' : 'Activé'">
                                    <button v-if="!row.item.status" @click="changeStatus(row.item)" class="btn btn-sm btn-primary">Activé</button>
                                    <button v-if="row.item.status"  @click="changeStatus(row.item)" class="btn btn-sm btn-primary">Désactivé</button>
                                </a>

                                <a href="javascript:void(0);" @click="showUpdateForm(row.item)" class="mr-3 text-primary"
                                    v-b-tooltip.hover data-toggle="tooltip" title="Modifier">
                                    <i class="mdi mdi-pencil font-size-18"></i>
                                </a>
                                <a href="javascript:void(0);" @click="showDeleteModal(row.item)" class="text-danger"
                                    v-b-tooltip.hover title="Supprimer">
                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                </a>
                            </template>
                            <template v-slot:cell(image)="r">
                                <img :src="`${filesBaseUrl}/actualite/${r.item.image}`" height="50"
                                    alt="">
                            </template>
                            <!-- <template v-slot:cell(contenu)="r">
                                <p style="height: 50px; text-overflow: ellipsis;" v-html="r.item.contenu"></p>

                            </template> -->
                            <template v-slot:cell(status)="r">
                                <b-badge v-if="r.item.status" variant="info" class="ml-1">Activé</b-badge>
                                <b-badge v-if="!r.item.status" variant="warning" class="ml-1">Désactivé</b-badge>
                            </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination v-model="currentPage" :total-rows="rows"
                                        :per-page="perPage"></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="delete-modal-center" centered :title="'Supression'" title-class="font-18" @ok="deleteData">
            <p>
                Confirmez-vous l'action?
            </p>
        </b-modal>
    </Layout>
</template>
